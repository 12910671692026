<template>
  <v-container>
    <v-card color="#E9EDED" elevation="10" style="opacity: 0.95">
      <v-container>
        <v-carousel hide-delimiters show-arrows="hover" continuous :height="carouselHeight">
          <v-carousel-item
            v-for="(img, index) in images"
            :key="index"
            cover
            :src="require(`../assets/${img.src}.jpg`)"
            :alt="img.alt"></v-carousel-item>
        </v-carousel>
      </v-container>

      <v-container>
        <v-row>
          <v-col cols="12" class="text-center pb-0">
            <h1 class="title" :style="titleStyle">Reuniones</h1>
          </v-col>
        </v-row>

        <v-row align="center" justify="center">
          <v-col :cols="smAndDown ? 12 : 4" class="pt-0">
            <v-divider color="#344966"></v-divider>
          </v-col>
        </v-row>

        <v-row align="center">
          <v-col>
            <p class="text text-center">
              Miércoles y Viernes - 7:30 PM <br />
              Domingo - 1 PM
            </p>
          </v-col>
        </v-row>
      </v-container>

      <v-container>
        <v-row align="center">
          <v-col cols="12" class="text-center pb-0">
            <h1 class="title" :style="titleStyle">Nosotros</h1>
          </v-col>
        </v-row>

        <v-row align="center" justify="center">
          <v-col :cols="smAndDown ? 12 : 5" class="pt-0">
            <v-divider color="#344966"></v-divider>
          </v-col>
        </v-row>

        <v-row align="center" justify="center">
          <v-col :cols="smAndDown ? 12 : 8">
            <p class="text text-center">
              ¡Bienvenidos a nuestra iglesia Cristiana Pentecostés! Nos alegra que hayan visitado nuestra página y esperamos poder recibirlos pronto
              en persona. Nuestro propósito es compartir el mensaje de salvación con el fin de que las almas vengan a los pies de Cristo. Creemos en
              la importancia de la oración y la alabanza, y en la obra del Espíritu Santo en nuestras vidas. ¡Esperamos verles pronto!
            </p>
          </v-col>
        </v-row>
      </v-container>

      <v-container>
        <v-row align="center">
          <v-col cols="12" class="text-center pb-0">
            <h1 class="title" :style="titleStyle">Eventos</h1>
          </v-col>
        </v-row>

        <v-row align="center" justify="center">
          <v-col :cols="smAndDown ? 12 : 3" class="pt-0">
            <v-divider color="#344966"></v-divider>
          </v-col>
        </v-row>

        <v-row align="center" justify="center">
          <v-col :cols="smAndDown ? 12 : 8">
            <p class="text text-center">
              Acompañenos en nuestros <router-link to="/eventos" class="link">Eventos</router-link> especiales este año. Sera un tiempo de bendición y
              de crecimiento espiritual. ¡No se los pierdan!
            </p>
          </v-col>
        </v-row>
      </v-container>

      <v-container>
        <v-row align="center">
          <v-col cols="12" class="text-center pb-0">
            <h1 class="title" :style="titleStyle">Contáctenos</h1>
          </v-col>
        </v-row>

        <v-row align="center" justify="center">
          <v-col :cols="smAndDown ? 12 : 4" class="pt-0">
            <v-divider color="#344966"></v-divider>
          </v-col>
        </v-row>

        <v-row align="center" justify="center">
          <v-col :cols="smAndDown ? 12 : 8">
            <p class="text text-center">
              Tiene una peticion o necesita oracion? <router-link to="/contacto" class="link">Contáctenos</router-link>. <br />
              <br />
            </p>
            <p class="bibleText text-center">
              "Él les dijo: Lo que es imposible para los hombres, es posible para Dios." <br />
              Lucas 18:27
            </p>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-container>
</template>

<script>
import { reactive, computed } from 'vue';
import { useDisplay } from 'vuetify';
export default {
  setup() {
    const images = reactive([
      {
        src: 'lema2025',
        alt: 'Lema de Resplandor De Gloria 2025 - Avivamiento Espiritual',
      },
      {
        src: 'pic1',
        alt: 'Pastor de la iglesia Resplandor De Gloria Blue Island adorando a Dios en el altar',
      },
      {
        src: 'pic2',
        alt: 'Pastora de la iglesia Resplandor De Gloria Blue Island adorando a Dios en el altar',
      },
      {
        src: 'pic3',
        alt: 'La iglesia Resplandor De Gloria Blue Island adorando a Dios',
      },
    ]);

    const { smAndDown, xlAndUp } = useDisplay();

    window.requestAnimationFrame(() => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    });

    let carouselHeight = computed(() => {
      if (smAndDown.value) return '250';
      else if (xlAndUp.value) return '800';
      else return '600';
    });

    let titleStyle = computed(() => {
      if (smAndDown.value) return 'font-size: 45px';
      else return 'font-size: 50px';
    });

    return {
      images,
      smAndDown,
      carouselHeight,
      titleStyle,
    };
  },
};
</script>

<style scoped>
.title {
  font-family: 'Sacramento';
  font-weight: normal;
  color: #a8b42d;
}

a {
  font-family: 'Aboreto';
  font-size: 20px;
}

.link {
  color: #344966;
}

.card {
  background-color: #fafbef;
}

.text {
  font-family: 'Gotu';
  font-size: 20px;
  color: #344966;
}

.bibleText {
  font-family: 'Gotu';
  font-style: italic;
  font-size: 16px;
  color: #344966;
}
</style>
