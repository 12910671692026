<template>
  <v-row
    v-for="(event, index) in events"
    :key="index"
    justify="center"
    align="center"
    align-content="center"
    class="event-row"
  >
    <v-col align-self="center" class="px-0">
      <v-card min-width="343" elevation="5">
        <v-img
          v-if="event.image"
          :src="require(`../../assets/${event.image}`)"
          :alt="event.alt"
          cover
        ></v-img>
        <v-card-actions v-if="event.eventPassed && event.hasAddressInfo">
          <v-spacer></v-spacer>
          <v-btn
            :append-icon="event.expandCard ? 'mdi-chevron-up' : 'mdi-chevron-down'"
            @click="event.expandCard = !event.expandCard"
            >
            Mas Información
          </v-btn>
        </v-card-actions>
        <v-expand-transition>
          <div v-show="event.expandCard && event.hasAddressInfo">
            <v-divider></v-divider>

            <v-container fluid>
              <p class="text">
                <!-- Date -->
                <v-row no-gutters>
                  <v-col :cols="smAndDown ? 2 : 1">
                    <v-icon size="x-large">mdi-calendar</v-icon>
                  </v-col>
                  <v-col :cols="smAndDown ? 10 : 11" align-self="center">
                    <span>{{ event.dateText }}</span>
                  </v-col>
                </v-row>

                <!-- Time -->
                <v-row>
                  <v-col :cols="smAndDown ? 2 : 1">
                    <v-icon size="x-large">mdi-clock</v-icon>
                  </v-col>
                  <v-col :cols="smAndDown ? 10 : 11" align-self="center">
                    <span v-html="event.time"></span>
                  </v-col>
                </v-row>

                <!-- Address -->
                <v-row>
                  <v-col :cols="smAndDown ? 2 : 1">
                    <v-icon size="x-large">mdi-map-marker</v-icon>
                  </v-col>
                  <v-col :cols="smAndDown ? 10 : 11" align-self="center">
                    <a
                      :href="
                        platform.ios ? event.appleMapsLink : event.googleMapsLink
                      "
                      target="_blank"
                      class="link-text"
                    >
                      <span>{{ event.streetAddress }}</span>
                      <br />
                      <span>{{ event.cityStateZip }}</span>
                    </a>
                  </v-col>
                </v-row>
              </p>
            </v-container>
          </div>
        </v-expand-transition>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { useDisplay } from "vuetify";

export default {
  props: {
    events: {
      type: Array,
      required: true,
    },
  },
  setup() {
    const { smAndDown, xlAndUp, platform } = useDisplay();

    return {
      smAndDown,
      xlAndUp,
      platform,
    };
  },
};
</script>

<style scoped>

.link-text {
  font-size: 16px;
  font-style: italic;
  color: #344966;
}

.text {
  font-family: 'Gotu';
  font-size: 16px;
  color: #344966;
}

.event-info {
  padding-left: 16px;
}

.event-row {
  padding-left: 4px;
  padding-right: 4px;
}

</style>
