<template>
  <v-container>
    <meta name="description" content="Eventos de la obra Resplandor De Gloria para el año 2024" />
    <v-row align="center" justify="center">
      <v-col cols="12" class="text-center">
        <h1 class="title" :style="titleStyle">Eventos | 2025</h1>
      </v-col>
    </v-row>

    <EventCards :events="events" />
  </v-container>
</template>

<script>
import { ref, computed } from 'vue';
import { useDisplay } from 'vuetify';
import EventCards from '@/components/events/EventCards.vue';

export default {
  components: {
    EventCards,
  },
  setup() {
    let tab = ref('upcoming');

    const events = ref([
      {
        image: '02082025.svg',
        alt: 'Confraternidad Por Sectores',
        startDate: '02/08/25',
        endDate: '02/08/25',
        dateText: 'sábado 8 de febrero',
        streetAddress: null,
        cityStateZip: "Green Bay, WI",
        time: '11:00 AM',
        googleMapsLink: null,
        appleMapsLink: null,
      },
      {
        image: '02262025.svg',
        alt: 'Retiro Nacional de Pastores y Lideres',
        startDate: '02/26/25',
        endDate: '02/28/25',
        dateText: 'miércoles 26 de febrero a viernes 28 de febrero',
        streetAddress: null,
        cityStateZip: "Atlanta, GA",
        time: '11:00 AM',
        googleMapsLink: null,
        appleMapsLink: null,
      },
      {
        image: '03292025.svg',
        alt: 'Confraternidad de Damas',
        startDate: '03/29/25',
        endDate: '03/29/25',
        dateText: 'sábado 29 de marzo',
        streetAddress: "1848 Summit Rd",
        cityStateZip: "Cincinnati, OH 45237",
        time: '11:00 AM',
        googleMapsLink: 'https://goo.gl/maps/yMD1ipnKGh2vhYoU7',
        appleMapsLink: 'https://maps.apple.com/place?q=1848%20Summit%20Rd&ll=39.2021968%2C-84.4542064&address=1848%20Summit%20Rd%2C%20Cincinnati%2C%20OH%20%2045237%2C%20United%20States',
      },
      {
        image: '04122025.svg',
        alt: 'Confraternidad de Jovenes',
        startDate: '04/12/25',
        endDate: '04/12/25',
        dateText: 'sábado 12 de abril',
        streetAddress: null,
        cityStateZip: "Michigan",
        time: '11:00 AM',
        googleMapsLink: null,
        appleMapsLink: null,
      },
      {
        image: '05172025.svg',
        alt: 'Confraternidad de Caballeros',
        startDate: '05/17/25',
        endDate: '05/17/25',
        dateText: 'sábado 17 de mayo',
        streetAddress: null,
        cityStateZip: "New Jersey",
        time: '11:00 AM',
        googleMapsLink: null,
        appleMapsLink: null,
      },
      {
        image: '06252025.svg',
        alt: 'Convención Nacional',
        startDate: '06/25/25',
        endDate: '06/27/25',
        dateText: 'miércoles 25 de junio a viernes 27 de junio',
        streetAddress: null,
        cityStateZip: "Cincinnati, OH",
        time: 'miercoles: 7:00 PM <br/> jueves y viernes: 9:00 AM y 6:00 PM',
        googleMapsLink: null,
        appleMapsLink: null,
      },
      {
        image: '07252025.svg',
        alt: 'Campamento de Caballeros',
        startDate: '07/25/25',
        endDate: '07/26/25',
        dateText: 'viernes 25 de julio a sábado 26 de julio',
        streetAddress: null,
        cityStateZip: null,
        time: null,
        googleMapsLink: null,
        appleMapsLink: null,
      },
      {
        image: '08152025.svg',
        alt: 'Campamento de Damas',
        startDate: '08/15/25',
        endDate: '08/16/25',
        dateText: 'viernes 15 de agosto a sábado 16 de agosto',
        streetAddress: null,
        cityStateZip: null,
        time: null,
        googleMapsLink: null,
        appleMapsLink: null,
      },
      {
        image: '09202025.svg',
        alt: 'Confraternidad de Damas y Caballeros',
        startDate: '09/20/25',
        endDate: '09/20/25',
        dateText: 'sábado 20 de septiembre',
        streetAddress: null,
        cityStateZip: "Columbus, OH",
        time: '11:00 AM',
        googleMapsLink: null,
        appleMapsLink: null,
      },
      {
        image: '10182025.svg',
        alt: 'Confraternidad por Sectores',
        startDate: '10/18/25',
        endDate: '10/18/25',
        dateText: 'sábado 18 de octubre',
        streetAddress: null,
        cityStateZip: "Kenosha, WI",
        time: '11:00 AM',
        googleMapsLink: null,
        appleMapsLink: null,
      },
      {
        image: '11262025.svg',
        alt: 'Convención Nacional',
        startDate: '11/26/25',
        endDate: '11/28/25',
        dateText: 'miércoles 26 de noviembre a viernes 28 de noviembre',
        streetAddress: null,
        cityStateZip: "Houston, TX",
        time: 'miercoles: 7:00 PM <br/> jueves y viernes: 9:00 AM y 6:00 PM',
        googleMapsLink: null,
        appleMapsLink: null,
      },
      {
        image: '12132025.svg',
        alt: 'Confraternidad General',
        startDate: '12/13/25',
        endDate: '12/13/25',
        dateText: 'sábado 13 de diciembre',
        streetAddress: null,
        cityStateZip: "Indianapolis, IN",
        time: '11:00 AM',
        googleMapsLink: null,
        appleMapsLink: null,
      }
    ]);

    const eventPassed = (targetDate, currentDate) => {
      return targetDate < currentDate;
    };

    const hasAllAddressInfo = (event) => {
      return event.streetAddress && event.cityStateZip && 
            event.googleMapsLink && event.appleMapsLink;
    };

    for (let i = 0; i < events.value.length; i++) {
      const targetEvent = events.value[i];

      let todaysDate = new Date();
      let targetEventEndDate = new Date(targetEvent.endDate);

      todaysDate.setHours(0, 0, 0, 0);
      targetEventEndDate.setHours(0, 0, 0, 0);

      if (eventPassed(targetEventEndDate, todaysDate)) {
        targetEvent.expandCard = false;
        targetEvent.eventPassed = true;
      } else {
        targetEvent.expandCard = true;
        targetEvent.eventPassed = false;
      }

      if (hasAllAddressInfo(targetEvent)) {
        targetEvent.hasAddressInfo = true;
      } else {
        targetEvent.hasAddressInfo = false;
      }
    }

    const { smAndDown, xlAndUp, mobile, platform } = useDisplay();

    let titleStyle = computed(() => {
      return {
        fontSize: mobile.value ? '30px' : '40px',
      };
    });

    window.requestAnimationFrame(() => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    });

    return {
      events,
      smAndDown,
      mobile,
      titleStyle,
      xlAndUp,
      platform,
      tab,
    };
  },
};
</script>

<style scoped>
.title {
  font-family: 'Gotu';
  font-weight: normal;
  font-size: 40px;
  color: #344966;
}

::v-deep .v-btn__content {
  font-family: 'Aboreto';
  text-transform: none;
  font-size: 16px;
  color: #172b3b;
}

.v-window {
  overflow: unset !important;
}
</style>
