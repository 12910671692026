<template>
  <v-app>
    <TheHeader />
    <v-main class="main">
      <router-view v-slot="slotProps">
        <transition name="route" mode="out-in">
          <component :is="slotProps.Component"></component>
        </transition>
      </router-view>
    </v-main>
    <TheFooter />
  </v-app>
</template>

<script>
import TheFooter from "./components/ui/TheFooter.vue";
import TheHeader from "./components/ui/TheHeader.vue";
export default {
  name: "App",

  components: {
    TheFooter,
    TheHeader,
  },

  data: () => ({
    //
  }),

  watch: {
    // watch for route changes
    $route(to) {
      document.title =
        to.meta.title + " | " + "Resplandor De Gloria Blue Island";
    },
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css?family=Montserrat");

@import url("https://fonts.googleapis.com/css?family=Sacramento");
@import url("https://fonts.googleapis.com/css?family=Aboreto");
@import url("https://fonts.googleapis.com/css?family=Gotu");

.route-enter-from {
  opacity: 0;
}

.route-leave-to {
  opacity: 0;
}

.route-enter-active {
  transition: all 0.3s ease-out;
}

.route-leave-active {
  transition: all 0.3s ease-in;
}

.route-enter-to,
.route-leave-from {
  opacity: 1;
}

.main {
  background-color: #f4f6f6;
}

.grecaptcha-badge {
  visibility: hidden;
}
</style>
